@tailwind base;
@tailwind components;
@tailwind utilities;

th {
    @apply text-left
}


header a {
    @apply underline hover:bg-blue-700 hover:py-2;
}

a {
    @apply underline hover:bg-blue-100;
}
